<app-loader [isLoading]="isLoading"></app-loader>
<div class="container flex-column padding">
  <div class="d-flex flex-center">
    <img class="logo" [src]="whiteLabel?.logo" [alt]="whiteLabel?.provider" />
  </div>
  <div class="form-wrapper">
    <form #f="ngForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label floating>Email</mat-label>
        <input
          matInput
          type="text"
          name="email"
          [(ngModel)]="email"
          autocomplete="email"
          email
          required
        />
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        class="custom-button"
        type="submit"
        [disabled]="!f.valid"
      >
        Send Reset Email
      </button>
    </form>
  </div>
  <div class="d-flex flex-center sign-in">
    Already have an account?&nbsp;
    <a routerLink="/sign-in">Sign in here.</a>
  </div>
</div>
