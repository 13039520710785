<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>{{ data.message }}</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngFor="let action of data.buttons"
    mat-button
    (click)="onButtonClick(action)"
  >
    {{ action.text }}
  </button>
</mat-dialog-actions>
