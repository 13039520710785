import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Contract } from 'src/app/models/Contract';
import { AgreementService } from '../agreement/agreement.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  collectionName = 'contracts';

  constructor(
    private readonly _afs: AngularFirestore,
    private readonly _authService: AuthService,
    private agreementService: AgreementService
  ) {}

  /**
   * Creates a new contract. The ID is the user's ID.
   * The text is copied from the latest agreement.
   */
  create(): Observable<any> {
    return this._authService.currentUser$.pipe(
      mergeMap((currentUser) => {
        const newDoc = this._afs.doc<Contract>(
          `${this.collectionName}/${currentUser?.uid}`
        );
        return this.agreementService.latestAgreement$.pipe(
          mergeMap((a) =>
            newDoc.set({
              terms: a.terms,
              privacyPolicy: a.privacyPolicy,
              datetimeCreated: new Date(),
            })
          )
        );
      })
    );
  }
}
