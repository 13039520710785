import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AccountUser } from 'src/app/models/AccountUser';
import { AuthService } from '../auth/auth.service';
import { ROLE } from '../../enums/Role';
import { STATUS } from '../../enums/Status';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, map, mapTo, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  collectionName = 'accounts';

  constructor(
    private readonly _afs: AngularFirestore,
    private readonly _authService: AuthService
  ) {}

  /**
   * Creates a new account & adds creator as admin
   */
  create() {
    const id = this._afs.createId();
    const newAccountDoc = this._afs.doc(this.collectionName + '/' + id);
    return this._authService.currentUser$.pipe(
      mergeMap((currentUser) =>
        from(
          newAccountDoc
            .set({
              owner: currentUser.uid,
              isSetup: false,
            })
            .then((_) => {
              const newAccountUserDoc = newAccountDoc
                .collection<AccountUser>('users')
                .doc(currentUser.uid);
              newAccountUserDoc.set({
                role: ROLE.ADMIN,
                status: STATUS.ACTIVE,
              });
              return id;
            })
        )
      )
    );
  }

  /**
   * Adds a user to an account's user collection
   */
  addUser(accountID: string) {
    const accountDocument = this._afs
      .collection<any>(this.collectionName)
      .doc(accountID);
    return this._authService.currentUser$.pipe(
      mergeMap((currentUser) => {
        const { uid } = currentUser;
        const accountUserDocument = accountDocument
          .collection<AccountUser>('users')
          .doc(uid);
        return from(
          accountUserDocument.set({ role: ROLE.USER, status: STATUS.ACTIVE })
        ).pipe(
          mapTo(true),
          catchError((err) => {
            console.error(err);
            return of(false);
          })
        );
      })
    );
  }

  /**
   * Checks if a user is active
   */
  checkUserStatus(): Observable<void> {
    return combineLatest([
      this._authService.currentUser$,
      this._authService.userDoc,
    ]).pipe(
      mergeMap(([currentUser, userDocument]) => {
        const { uid } = currentUser;
        return userDocument.valueChanges().pipe(
          mergeMap((user) => {
            if (!user.defaultAccountId) {
              return of(null);
            }
            return this._afs
              .collection<any>(this.collectionName)
              .doc(user.defaultAccountId)
              .collection<any>('users')
              .doc<AccountUser>(uid)
              .valueChanges()
              .pipe(
                map((accountUser) => {
                  let isActive =
                    accountUser && accountUser.status === STATUS.ACTIVE;
                  if (!isActive) {
                    throw new Error(
                      'Your account is inactive. Please contact your administrator.'
                    );
                  }
                  return null;
                })
              );
          })
        );
      })
    );
  }
}
