<app-loader [isLoading]="isLoading"></app-loader>
<div class="container flex-column padding">
  <div class="invite-wrapper d-flex flex-column flex-center" fixed>
    <img class="logo" [src]="whiteLabel?.logo" [alt]="whiteLabel?.provider" />
    <div>
      <div class="form-wrapper">
        <form [formGroup]="form" (ngSubmit)="submitForm()">
          <h3 class="open-sans title">Creating account for</h3>
          <h3 class="open-sans user-name">
            {{ cclInvite?.firstName }} {{ cclInvite?.lastName }}
          </h3>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input
              matInput
              type="password"
              name="password"
              formControlName="password"
              required
              minlength="6"
            />
            <mat-error>
              Password is required (minimum 6 characters).
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Verify Password </mat-label>
            <input
              matInput
              type="password"
              name="confirmPassword"
              formControlName="confirmPassword"
              [errorStateMatcher]="matcher"
              required
            />
            <mat-error> Password mismatch </mat-error>
          </mat-form-field>
          <mat-checkbox
            *ngIf="whiteLabel?.provider === 'ClientChatLive'"
            formControlName="subscribeToCcl"
            color="primary"
            name="subscribeToCcl"
          >
            Opt-in to receive future information and communication about
            products and services from Client Chat Live.
          </mat-checkbox>
          <button
            mat-raised-button
            color="primary"
            class="custom-button"
            type="submit"
            [disabled]="!form.valid"
          >
            Confirm
          </button>
          <p class="terms" *ngIf="whiteLabel?.provider === 'ClientChatLive'">
            By clicking confirm, you agree to our Terms of Use and
            <a target="_blank" href="https://clientchatlive.com/privacy-policy/"
              >Privacy policy</a
            >.
          </p>
        </form>
      </div>
    </div>
  </div>
</div>
