import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { Invite } from 'src/app/models/Invite';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  collectionName = 'invites';

  constructor(private afs: AngularFirestore) {}

  /**
   * Get the Firestore 'invites' document by its ID
   */
  getDoc(inviteId: string) {
    return this.afs.collection<Invite>(this.collectionName).doc(inviteId);
  }

  /**
   * Deletes an existing invite
   */
  delete(id: string) {
    return from(this.getDoc(id).delete()).pipe(
      mapTo(true),
      catchError((err) => {
        console.log(err);
        return of(false);
      })
    );
  }
}
