import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CclInviteComponent } from './routes/ccl-invite/ccl-invite.component';
import { InviteComponent } from './routes/invite/invite.component';
import { ResetPasswordComponent } from './routes/reset-password/reset-password.component';
import { SignInComponent } from './routes/sign-in/sign-in.component';

const routes: Routes = [
  { path: 'ci/:id', component: CclInviteComponent },
  { path: 'i/:inviteID', component: InviteComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
