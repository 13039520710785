import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WhiteLabel } from 'src/app/models/WhiteLabel';
import { AlertComponent } from 'src/app/modules/shared/components/alert/alert.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { WhiteLabelService } from 'src/app/services/white-label/white-label.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  email: string;
  whiteLabel: WhiteLabel;
  isLoading = false;

  constructor(
    private readonly _authService: AuthService,
    private readonly _whiteLabelService: WhiteLabelService,
    private readonly _dialog: MatDialog
  ) {
    this.whiteLabel = this._whiteLabelService.whiteLabel;
  }

  /**
   * When the form is submitted use its values to send the "Reset Password email", if it
   * success  use a MatDialog to show success message else use it to show error message.
   */
  onSubmit() {
    this.isLoading = true;
    this._authService
      .sendResetEmail(this.email)
      .then(() => {
        this.isLoading = false;
        this._dialog.open(AlertComponent, {
          data: {
            title: 'Reset Email Sent',
            message: 'Please check your inbox.',
            buttons: [{ text: 'OK' }],
          },
        });
      })
      .catch((error) => {
        this.isLoading = false;
        this._dialog.open(AlertComponent, {
          data: {
            title: 'Unable to Send Reset Email',
            message: error ? error.message : 'Please try again.',
            buttons: [{ text: 'OK' }],
          },
        });
      });
  }
}
