import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, merge } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Invite } from 'src/app/models/Invite';
import { AlertComponent } from 'src/app/modules/shared/components/alert/alert.component';
import { AccountService } from 'src/app/services/account/account.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ContractService } from 'src/app/services/contract/contract.service';
import { InviteService } from 'src/app/services/invite/invite.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit {
  inviteID: string;
  inviteDoc: AngularFirestoreDocument<Invite>;
  invite: Invite;
  password: string;
  verify: string;
  isLoading: boolean = true;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _inviteService: InviteService,
    private readonly _accountService: AccountService,
    private readonly _contractService: ContractService,
    private readonly _afs: AngularFirestore,
    private readonly _dialog: MatDialog
  ) {}

  /**
   * Check if the invitation ID used by the user exists, if not  it means it was used
   * and deleted so it redirects to Sign In
   */
  ngOnInit(): void {
    this.inviteID = this._activatedRoute.snapshot.paramMap.get('inviteID');
    this.inviteDoc = this._afs.collection<Invite>('invites').doc(this.inviteID);
    this.inviteDoc.snapshotChanges().subscribe((res) => {
      if (res.payload.exists) {
        this.invite = res.payload.data() as Invite;
      } else {
        this._router.navigate(['sign-in']);
      }
      this.isLoading = false;
    });
  }

  /**
   * When the form is submitted use its values to Sign Up, if it success, create a
   * contract with the user (DEPRECATED), create user document and delete the invitation
   * used to sign up. Finally redirect to the console / dashboard else use a
   * MatDialog to show an error.
   */
  submitForm() {
    this.isLoading = true;
    this._authService
      .signUp(
        this.invite.email,
        this.password,
        this.invite.firstName,
        this.invite.lastName
      )
      .subscribe(
        () => {
          return combineLatest([
            this._contractService.create(),
            this._authService.setDefaultAccountId(this.invite.account),
            this._accountService.addUser(this.invite.account),
            this._inviteService.delete(this.inviteID),
          ])
            .pipe(mergeMap(() => this._authService.goToConsole()))
            .subscribe(() => {});
        },
        (error) => {
          this.isLoading = false;
          this._dialog.open(AlertComponent, {
            data: {
              title: 'Unable to create account.',
              message: error ? error.message : 'Please try again.',
              buttons: [{ text: 'OK' }],
            },
          });
        }
      );
  }
}
