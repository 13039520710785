import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { User } from 'src/app/models/User';
import { WhiteLabelService } from '../white-label/white-label.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { combineLatest, from, Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userCollection: AngularFirestoreCollection<User>;

  constructor(
    private readonly _afAuth: AngularFireAuth,
    private readonly _afs: AngularFirestore,
    private readonly _whiteLabelService: WhiteLabelService,
    private readonly _fns: AngularFireFunctions
  ) {
    this.userCollection = this._afs.collection<User>('users');
  }

  /**
   * Get an Observable with the current authenticated user
   */
  get currentUser$(): Observable<firebase.default.User> {
    return from(this._afAuth.currentUser);
  }

  /**
   * Sign in using email and password
   */
  signIn(email: string, password: string) {
    return this._afAuth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Sign out
   */
  signOut() {
    this._afAuth.signOut();
  }

  /**
   * Search for a user on the Firestore 'users' collection using its email
   */
  findUserByEmail(email: string) {
    return this._afs
      .collection<User>(`users`, (ref) => ref.where('email', '==', email))
      .valueChanges()
      .pipe(
        map((snap) => {
          if (snap.length < 1) {
            return null;
          } else {
            return snap[0] as any;
          }
        })
      );
  }

  /**
   * Get an Observable with the Firestore document of the current authenticated user
   */
  get userDoc(): Observable<AngularFirestoreDocument<User>> {
    return this.currentUser$.pipe(
      map((user) => this.userCollection.doc<User>(user.uid))
    );
  }

  /**
   * Sign up with email and password for the Firebase Authentication, user's Firestore
   * document is created and additional user information is stored in the user document
   */
  signUp(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    isLiveChatUser: boolean = false
  ) {
    return from(
      this._afAuth.createUserWithEmailAndPassword(email, password)
    ).pipe(
      mergeMap((user) => combineLatest([this.userDoc, this.currentUser$])),
      mergeMap(([userDoc, currentUser]) => {
        userDoc.set(
          {
            isSuperAdmin: false,
            firstName: firstName,
            lastName: lastName,
            email: email,
            isLiveChatUser: isLiveChatUser,
          },
          { merge: true }
        );
        return from(
          currentUser.updateProfile({
            displayName: `${firstName} ${lastName}`,
            photoURL: null,
          })
        );
      })
    );
  }

  /**
   * Sign up with email and password for the Firebase Authentication, user's Firestore
   * document is created and additional user information is stored in the user document.
   * The created user is LiveChatUser by default
   */
  createUser(user: any, password: string, isLiveChatUser: boolean) {
    return from(
      this._afAuth.createUserWithEmailAndPassword(user.email, password)
    ).pipe(
      mergeMap(() => combineLatest([this.userDoc, this.currentUser$])),
      mergeMap(([userDocument, currentUser]) => {
        let userDoc = userDocument;
        userDoc.set(
          {
            ...user,
            isSuperAdmin: false,
            isLiveChatUser: true,
          },
          { merge: true }
        );
        return currentUser?.updateProfile({
          displayName: `${user.firstName} ${user.lastName}`,
          photoURL: null,
        });
      })
    );
  }

  /**
   * Updates the defaultAccountId of the current authenticated user Firestore document
   */
  setDefaultAccountId(accountId: string) {
    return this.userDoc.pipe(
      mergeMap((userDocument) =>
        (userDocument as AngularFirestoreDocument).set(
          { defaultAccountId: accountId },
          { merge: true }
        )
      )
    );
  }

  /**
   * Redirects to console
   */
  goToConsole() {
    return this.goToConsoleOrRedirectionUrl(
      this._whiteLabelService.whiteLabel.consoleUrl
    );
  }

  /**
   * Redirects to the console with the autentication token as query param
   */
  goToConsoleOrRedirectionUrl(consoleUrl: string) {
    const getCustomToken = this._fns.httpsCallable('getCustomToken');
    return getCustomToken({}).pipe(
      map((result) => result.token),
      map((token) => {
        window.open(consoleUrl + '?token=' + token, '_self', 'location=yes');
        return false;
      })
    );
  }

  /**
   * Send an email to reset the password
   */
  sendResetEmail(email: string) {
    return this._afAuth.sendPasswordResetEmail(email);
  }

  /**
   * Send an email to verify the account
   */
  sendVerification(): Observable<any> {
    return this.currentUser$.pipe(
      mergeMap((currentUser) =>
        from<any>(
          currentUser?.sendEmailVerification({
            url: `${environment.authURL}?email=${currentUser?.email}`,
          })
        )
      )
    );
  }

  /**
   * Checks if a user is verified
   */
  checkVerification() {
    return this.currentUser$.pipe(
      map((currentUser) => {
        if (!currentUser?.emailVerified) {
          throw new Error('Your email is unverified. Please check your inbox.');
        }
      })
    );
  }
}
