import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertButton } from '../../models/AlertButton';
import { AlertData } from '../../models/AlertData';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  constructor(
    private readonly _dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertData
  ) {}

  /**
   * Executes any extra handler provided to the MatDialog
   */
  onButtonClick(action: AlertButton) {
    if (action.handler) {
      action.handler();
    }
    this.defaultButtonHandler();
  }

  /**
   * By default close the MatDialog
   */
  defaultButtonHandler(): void {
    this._dialogRef.close();
  }
}
