import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { WhiteLabel } from 'src/app/models/WhiteLabel';
import { AlertComponent } from 'src/app/modules/shared/components/alert/alert.component';
import { CustomErrorStateMatcher } from 'src/app/modules/shared/utils/CustomErrorStateMatcher';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CclInviteService } from 'src/app/services/ccl-invite/ccl-invite.service';
import { ContractService } from 'src/app/services/contract/contract.service';
import { WhiteLabelService } from 'src/app/services/white-label/white-label.service';
import { CclInvite } from '../../models/CclInvite';
@Component({
  selector: 'app-ccl-invite',
  templateUrl: './ccl-invite.component.html',
  styleUrls: ['./ccl-invite.component.scss'],
})
export class CclInviteComponent implements OnInit {
  id: string;
  doc: AngularFirestoreDocument<any>;
  cclInvite: CclInvite;
  isLoading: boolean = true;
  whiteLabel: WhiteLabel;
  form: FormGroup;
  matcher = new CustomErrorStateMatcher();

  constructor(
    private readonly _authService: AuthService,
    private readonly _ciService: CclInviteService,
    private readonly _contractService: ContractService,
    private readonly _afs: AngularFirestore,
    private _whiteLabelService: WhiteLabelService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    private readonly _formbuilder: FormBuilder
  ) {
    this.whiteLabel = this._whiteLabelService.whiteLabel;
    this.form = this._formbuilder.group(
      {
        password: ['', Validators.required],
        confirmPassword: [''],
        subscribeToCcl: [false],
      },
      { validators: this.checkPasswords }
    );
  }

  /**
   * Check if the invitation ID used by the user exists, if not  it means it was used
   * and deleted so it redirects to Sign In
   */
  ngOnInit(): void {
    this.id = this._activatedRoute.snapshot.paramMap.get('id');
    this.doc = this._afs.collection<any>('ccl-invites').doc(this.id);
    this.doc.snapshotChanges().subscribe((res) => {
      if (res.payload.exists) {
        this.cclInvite = res.payload.data();
      } else {
        this._router.navigate(['sign-in']);
      }
      this.isLoading = false;
    });
  }

  /**
   * Validator to check if the two provided passwords matches.
   */
  checkPasswords(group: AbstractControl): ValidationErrors | null {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { passwordMismatch: true };
  }

  /**
   * When the form is submitted use its values to Sign Up, if it success, create a
   * contract with the user (DEPRECATED), create user document and delete the invitation
   * used to sign up. Finally redirect to the console / dashboard else use a
   * MatDialog to show an error.
   */
  submitForm() {
    const { password, subscribeToCcl } = this.form.value;
    this.isLoading = true;
    const data = Object.assign({}, this.cclInvite) as any;
    // delete props we don't want to pass to the user
    delete data.accountName;
    data.subscribeToCcl = subscribeToCcl;
    this._authService.createUser(data, password, true).subscribe(
      (_) => {
        this._ciService.delete(this.id);
        return this._contractService.create().subscribe(() => {
          this._authService.goToConsole().subscribe();
        });
      },
      (error) => {
        this.isLoading = false;
        this._dialog.open(AlertComponent, {
          data: {
            title: 'Unable to create account.',
            message: error ? error.message : 'Please try again.',
            buttons: [{ text: 'OK' }],
          },
        });
      }
    );
  }
}
