<app-loader [isLoading]="isLoading"></app-loader>
<div class="container flex-column">
  <div class="signin-wrapper">
    <div class="d-flex flex-center">
      <img class="logo" [src]="whiteLabel?.logo" [alt]="whiteLabel?.provider" />
    </div>
  </div>
  <div class="form-wrapper">
    <form #f="ngForm" (ngSubmit)="onSignIn(f)">
      <h3 class="open-sans">Log in to your account</h3>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          #emailInput="ngModel"
          type="text"
          name="email"
          [(ngModel)]="email"
          autocomplete="email"
          email
          required
        />
        <mat-error>
          {{
            emailInput?.errors?.required
              ? "This field is required"
              : "Please enter a valid email address"
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          matInput
          #passwordInput="ngModel"
          type="password"
          name="password"
          [(ngModel)]="password"
          autocomplete="current-password"
          required
        />
        <mat-error> This field is required </mat-error>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        class="custom-button"
        type="submit"
        [disabled]="!f.valid"
      >
        Sign In
      </button>
    </form>
  </div>
  <div class="d-flex flex-center">
    <div class="forgot-password">
      Forgot password?
      <a routerLink="/reset-password">Reset here.</a>
    </div>
  </div>
</div>
