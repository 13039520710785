import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Agreement } from 'src/app/models/Agreement';

@Injectable({
  providedIn: 'root',
})
export class AgreementService {
  collectionName = 'agreements';
  latestAgreement$: Observable<Agreement>;

  constructor(private readonly _afs: AngularFirestore) {
    this.getLatestAgreement();
  }

  /**
   * Get the latest created agrrement on the Firestore 'agreements' collection
   */
  getLatestAgreement() {
    this.latestAgreement$ = this._afs
      .collection<Agreement>('agreements', (ref) =>
        ref.orderBy('datetimeCreated', 'desc').limit(1)
      )
      .valueChanges()
      .pipe(
        map((agreements) => agreements[0]),
        shareReplay(1)
      );
  }
}
