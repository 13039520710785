<app-loader [isLoading]="isLoading"></app-loader>
<div class="container flex-column padding">
  <div class="d-flex flex-column flex-center">
    <div *ngIf="invite">
      Creating account for {{ invite.firstName }} {{ invite.lastName }}
    </div>
    <form #f="ngForm">
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          matInput
          type="password"
          name="password"
          [(ngModel)]="password"
          #passwordInput="ngModel"
          autocomplete="current-password"
          required
          minlength="6"
        />
        <mat-error>Password is required (minimum 6 characters).</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label floating>Verify Password</mat-label>
        <input
          matInput
          type="password"
          name="verify"
          [(ngModel)]="verify"
          #verifyInput="ngModel"
          pattern="{{ password }}"
          required
        />
        <mat-error>Password mismatch.</mat-error>
      </mat-form-field>
      <div>
        <p>By clicking Sign Up, you agree to our Terms of Use.</p>
      </div>
      <button
        mat-raised-button
        color="primary"
        class="custom-button"
        [disabled]="!f.valid"
        (click)="submitForm()"
      >
        Sign Up
      </button>
    </form>
  </div>
</div>
