import { Injectable } from '@angular/core';
import { WHITE_LABEL_MAPPINGS } from 'src/app/enums/WhiteLabel';
import { WhiteLabel } from 'src/app/models/WhiteLabel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  whiteLabel: WhiteLabel;

  /**
   * Maps the URL to its corresponding Withe Label's config
   */
  constructor() {
    if (document.location.origin.includes('dlm')) {
      this.whiteLabel = WHITE_LABEL_MAPPINGS['digitalLawMarketing'];
    } else if (document.location.origin.includes('sokolove')) {
      this.whiteLabel = WHITE_LABEL_MAPPINGS['sokolove'];
    } else if (document.location.origin.includes('focusdigital')) {
      this.whiteLabel = WHITE_LABEL_MAPPINGS['focusDigital'];
    } else if (document.location.origin.includes('compulselegal')) {
      this.whiteLabel = WHITE_LABEL_MAPPINGS['compulselegal'];
    } else if (document.location.origin.includes('cclwhitelabel')) {
      this.whiteLabel = WHITE_LABEL_MAPPINGS['cclWhiteLabel'];
    } else if (document.location.origin.includes('chatleadspro.com')) {
      this.whiteLabel = WHITE_LABEL_MAPPINGS['chatLeadsPro'];
    } else {
      this.whiteLabel = WHITE_LABEL_MAPPINGS['clientChatLive'];
    }
    if (this.whiteLabel.domain.replace(/[^\.]/g, '').length > 1) {
      this.whiteLabel.consoleUrl = `https://${this.whiteLabel.domain}`;
    } else {
      this.whiteLabel.consoleUrl = `https://${environment.subdomain}${this.whiteLabel.domain}/`;
    }
    document.title = `${this.whiteLabel.provider} Reviews`;
  }
}
