import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CclInvite } from 'src/app/models/CclInvite';

@Injectable({
  providedIn: 'root',
})
export class CclInviteService {
  collectionName = 'ccl-invites';

  constructor(private readonly _afs: AngularFirestore) {}

  /**
   * Get the Firestore 'ccl-invites' document by its ID
   */
  getDoc(id: string) {
    return this._afs.collection<CclInvite>(this.collectionName).doc(id);
  }

  /**
   * Deletes an existing CCL invite
   */
  delete(id: string): Promise<void> {
    return this.getDoc(id).delete();
  }
}
