import { environment } from 'src/environments/environment';
import { WhiteLabel } from '../models/WhiteLabel';

export const WHITE_LABEL_MAPPINGS: { [key: string]: WhiteLabel } = {
  clientChatLive: {
    domain: `${environment.subdomain}clientchatlive.com`,
    provider: 'ClientChatLive',
    logo: '/assets/img/logo-orange.png',
  },
  chatLeadsPro: {
    domain: 'app.chatleadspro.com',
    provider: 'ChatLeadsPro',
    logo: '/assets/img/chat.jpg',
  },
  compulselegal: {
    domain: 'compulselegal.chatleadspro.com',
    provider: 'ChatLeadsPro',
    logo: '/assets/img/compulse.png',
  },
};
