import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { WhiteLabel } from 'src/app/models/WhiteLabel';
import { AuthService } from 'src/app/services/auth/auth.service';
import { WhiteLabelService } from 'src/app/services/white-label/white-label.service';
import { NgForm } from '@angular/forms';
import { from } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from 'src/app/modules/shared/components/alert/alert.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  redirectUrl: string;
  email: string;
  password: string;
  whiteLabel: WhiteLabel;
  isLoading = false;

  constructor(
    private readonly _authService: AuthService,
    private readonly _location: Location,
    private readonly _whiteLabelService: WhiteLabelService,
    private readonly _dialog: MatDialog
  ) {
    this.whiteLabel = this._whiteLabelService.whiteLabel;
  }

  /**
   * Initialize variables from query params if they were provided.
   */
  ngOnInit(): void {
    const path = this._location.path(true);
    if (path) {
      const params = new HttpParams({
        fromString: path.replace(/^([^?]*)?\??/, ''),
      });
      const emailParam = params.get('email');
      if (emailParam) {
        this.email = emailParam;
      }
      const redirectUrl = params.get('redirectUrl');
      if (redirectUrl) {
        this.redirectUrl = redirectUrl;
      }
    }
  }

  /**
   * When the form is submitted use its values to Sign In, if it success redirect to the
   * console / dashboard else show an error.
   */
  onSignIn(form: NgForm) {
    this.isLoading = true;
    from(this._authService.signIn(this.email, this.password)).subscribe(
      (_) => {
        const consoleUrl = this.redirectUrl || this.whiteLabel.consoleUrl;
        this._authService
          .goToConsoleOrRedirectionUrl(consoleUrl)
          .subscribe((_) => (this.isLoading = false));
      },
      (err) => this.onError(err.message)
    );
  }

  /**
   * Use a MatDialog to show an error message
   */
  onError(errorMessage?: string) {
    this.isLoading = false;
    this._dialog.open(AlertComponent, {
      data: {
        title: 'Unable to log in',
        message: errorMessage ? errorMessage : 'Please try again.',
        buttons: [{ text: 'OK' }],
      },
    });
  }
}
